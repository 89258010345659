import React from "react";
import Constraction from "../../../component/Constraction/Constraction";
const TechnicaEducation = () => {
  return (
    <div>
      <Constraction></Constraction>
    </div>
  );
};

export default TechnicaEducation;
