import React from "react";
import Constraction from "../../../component/Constraction/Constraction";
const DistributionWinterClothes = () => {
  return (
    <div>
      <Constraction></Constraction>
    </div>
  );
};

export default DistributionWinterClothes;
