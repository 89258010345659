import React from 'react';
import useTitle from '../../hooks/useTitle';
const Services = () => {
    useTitle("Services");
    return (
        <div>
            <h2>This is Services Page</h2>
        </div>
    );
};

export default Services;