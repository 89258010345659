import React from 'react';
import Constraction from '../../component/Constraction/Constraction';

const Pricing = () => {
    return (
        <div className='mt-20'>
            <Constraction></Constraction>
        </div>
    );
};

export default Pricing;